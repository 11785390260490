/**
 * Format message string for product validation messages object
 */
export function formatProductMessages (messages) {
  const msgs = []
  for (const infoKey in messages) {
    if (messages[infoKey]) {
      msgs.push(messages[infoKey])
    }
  }
  return msgs.join(', ')
}
