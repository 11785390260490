export default {
  title: 'Официальный сайт',
  titleTemplate: '%s | Maxxium Russia ',
  htmlAttrs: {
    lang: 'ru'
  },
  meta: [
    { charset: 'utf-8' },
    { vmid: 'description', name: 'description', content: 'Maxxium Russia – российское подразделение международного альянса двух ведущих компаний-производителей крепкого алкоголя Beam Suntory Inc. и The Edrington.' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, minimal-ui' },
    { vmid: 'robots', name: 'robots', content: 'index, follow' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' },
    { name: 'yandex-verification', content: '7e3d5fdd9e85b026' }
  ],
  link: [
    { rel: 'icon', type: 'image/png', href: '/assets/Favicon_48.png', sizes: '48x48' },
    { rel: 'icon', type: 'image/png', href: '/assets/Favicon_32.png', sizes: '32x32' },
    { rel: 'icon', type: 'image/png', href: '/assets/Favicon_16.png', sizes: '16x16' },
    { rel: 'apple-touch-icon', href: '/assets/Favicon_48.png' },
    // { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_2048.png', sizes: '2048x2732' },
    // { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1668.png', sizes: '1668x2224' },
    // { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1536.png', sizes: '1536x2048' },
    // { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1125.png', sizes: '1125x2436' },
    // { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1242.png', sizes: '1242x2208' },
    // { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_750.png', sizes: '750x1334' },
    // { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_640.png', sizes: '640x1136' },
    { rel: 'manifest', href: '/assets/manifest.json' }
    // { rel: 'stylesheet', href: 'https://fonts.googleapis.com/icon?family=Material+Icons&display=swap' }
  ]
}
